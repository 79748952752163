@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "Druk";
  src: url("../public/fonts/Druk-Wide-Cy-Web-Medium-Regular.ttf"); /* IE9 Compat Modes */
  src: url("../public/fonts/Druk-Wide-Cy-Web-Medium-Regular.ttf")
      format("truetype"),
    /* Modern Browsers */
      url("../public/fonts/Druk-Wide-Cy-Web-Medium-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "NeueMachina-Bold";
  src: url("../public/fonts/NeueMachina-Ultrabold.otf"); /* IE9 Compat Modes */
  src: url("../public/fonts/NeueMachina-Ultrabold.otf") format("truetype"),
    /* Modern Browsers */ url("../public/fonts/NeueMachina-Ultrabold.otf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Vela-Sans";
  src: url("../public/fonts/Vela-Sans.ttf"); /* IE9 Compat Modes */
  src: url("../public/fonts/Vela-Sans.ttf") format("truetype"),
    /* Modern Browsers */ url("../public/fonts/Vela-Sans.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

.main-title {
  font-family: "Druk";
}

.main-card-hexagon-text {
  font-family: "NeueMachina-Bold";
}

.vela-sans {
  font-family: "Vela-Sans";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
      253.9deg,
      #1e1e1e 58.43%,
      #370dce 83.67%,
      #1e0e59 110.53%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.additional-bg {
  background: url("../public/static/vertical-vector.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 95px;
  z-index: -1;
}

.additional-bg-transform {
  background: url("../public/static/vertical-vector.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  z-index: -1;
  transform: rotate(15deg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-card {
  background: linear-gradient(153.3deg, #0e0e0e 14.84%, #1e0e59 59.57%),
    linear-gradient(51.49deg, #0e0e0e 26.18%, #0478b9 101.81%),
    linear-gradient(0deg, rgba(14, 14, 14, 0.2), rgba(14, 14, 14, 0.2));
}

.rules-button {
  border: 1px solid #909098;
}

.kids-card {
  box-shadow: 0px 24px 50px 0px #0000000d;
}

.outer-card:hover div p {
  color: black;
}

.html-text a {
  color: blue;
}

a:focus {
  outline: none;
}
